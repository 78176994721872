import { React, useState } from 'react';
import styles from './Navbar.module.css';
import logo from '../../images/logoo.gif';
import {
  AiOutlineUser,
  AiOutlineSearch,
  AiOutlineMenu,
  AiOutlineClose,
} from 'react-icons/ai';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [nav, setNav] = useState(false);

  return (
    <header className={styles.navbar}>
      <Link to="/home">
        <img
          src={logo}
          alt="logo"
          style={{
            width: '70px',
            filter: 'brightness(150%)',
            backgroundColor: 'transparent',
            fontWeight: 'bold'
          }}

        />

      </Link>
      <nav>
        <ul className={nav ? [styles.menu, styles.active].join(' ') : [styles.menu]} >
          <li>
            <Link to="/OrderList">
              <a href='/OrderList' className='my-order'>My Orders</a>

            </Link>
          </li>
          <li>
            
          </li>
          {/* <li>
            <AiOutlineSearch size={25} style={{ marginTop: '6px' }} />
          </li> */}
          <Link to="/Profile">
            <li>
              <AiOutlineUser color='black' size={25} style={{ marginTop: '2px' }} />
            </li>
          </Link>
        </ul>
      </nav>
      <div onClick={() => setNav(!nav)} className={styles.mobile_btn}>
        {nav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}

      </div>
    </header>
  );
};

export default Navbar;
