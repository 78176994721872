import React from 'react';
import logo from '../../images/logoo.gif';
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';

import styles from './Footer.module.css';

const Footer = () => {
  return (
    <div className="footer">
      <div className={styles.container}>
        <div className={styles.footerTop}>
          <div className={styles.contactUs}>
            <h4 style={{display:'flex'}}>Contact Us</h4>
            <p style={{textAlign: 'left', marginTop:'30px',marginBottom:'5rem'}}>Shop No 19 Baraut, Near Allahabad Bank<br></br>
            Baghpat Uttar Pradesh 250611<br></br>
            Mobile: +91 85330 03022</p>
          </div>
          <div >
            <h4>Quick Links</h4>
            <ul style={{marginRight:50}}>
              <li><a style={{textAlign:'left',color:'white'}} href="/AboutUs">About Us</a></li>
              <li><a style={{textAlign:'left',color:'white'}} href="/contact">Contact Us</a></li>
            </ul>
          </div>
          <div className={styles.connectWithUs}>
            <h4>Connect With Us</h4>
            <div className={styles.socialIcons}>
              <a  href="https://www.facebook.com"><FaFacebook /></a>
              <a href="https://www.twitter.com"><FaTwitter /></a>
              <a href="https://www.instagram.com"><FaInstagram /></a>
              <a href="https://www.youtube.com"><FaYoutube /></a>
            </div>
          </div>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.footerBottom}>
          <div className={styles.legalLinks}>
            <ul style={{justifyContent:'space-between',alignItems:'center'}}>
              <li><a style={{marginLeft:'10px'}} href="#copyright">@2024 Wecare allright reserved</a></li>
              <li><a style={{marginLeft:'500px'}} href="/Privacy">Privacy Policy</a></li>
              <li><a href="/Terms">Terms and Conditions</a></li>
              <li><a href="/RefundPolicy">Refund Policy</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
