import React, { useState } from "react";
import img from "../../images/contact.avif";
import "./style.css";
import Footer from "../footer/Footer";
import { toast } from "react-toastify";
import { SBaseUrl } from "../../Apis";
import WithouSignNavbar from "../WithouSignNavbar";

const defaultContactFormData = {
  name: "",
  email: "",
  contactNumber: "",
  issue: "",
  message: "",
};

const ContactForm = () => {
  const [contact, setContact] = useState(defaultContactFormData);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setContact({
      ...contact,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${SBaseUrl}/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contact),
      });

      if (response.ok) {
        setContact(defaultContactFormData);
        const data = await response.json();
        console.log(data);
        // Assuming you have a toast function available
        toast("Message sent successfully Our team reach you ASAP");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <WithouSignNavbar />
      <div className="contact-container">
        <div className="form-container">
          <h3>How can we help today?</h3>
          <form onSubmit={handleSubmit}>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your Name"
              value={contact.name}
              onChange={handleInput}
            />

            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your Email"
              value={contact.email}
              onChange={handleInput}
            />

            <label htmlFor="contactNumber">Contact Number:</label>
            <input
              type="text"
              id="contactNumber"
              name="contactNumber"
              placeholder="Your Contact Number"
              value={contact.contactNumber}
              onChange={handleInput}
            />

            <label htmlFor="issue">Issue:</label>
            <select
              id="issue"
              name="issue"
              style={{padding:10}}
              value={contact.issue}
              onChange={handleInput}
            >
              <option value="">Select an issue</option>
              <option value="Know my repair status">Know my repair status</option>
              <option value="Purchase a new plan">Purchase a new plan</option>
              <option value="Repair status">Repair status</option>
              <option value="Complain">Complain</option>
              <option value="Know about my refunds">Know about my refunds</option>
              <option value="Feedback to WeCare">Feedback to WeCare</option>
            </select>

            <label htmlFor="contactNumber">Sub Issue</label>
            <input
              type="text"
              id="subIssue"
              name="subIssue"
              placeholder="Please Add Sub Issue"
              value={contact.subIssue}
              onChange={handleInput}
            />

            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              placeholder="Your Message"
              value={contact.message}
              onChange={handleInput}
            ></textarea>

            <button type="submit">Submit</button>
          </form>
        </div>
        <div className="map-container">
          <h4>Office Address</h4>
          <p>Shop no 19 baraut  near allahabad bank Baghpat uttar pradesh 250611</p>
          <iframe
            title="Office Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.7076822417296!2d77.5907506143131!3d12.972295890856885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670d219e05f%3A0xc80f5e49b9b20e4!2sBengaluru%2C%20Karnataka%20560001!5e0!3m2!1sen!2sin!4v1620493326215!5m2!1sen!2sin"
            width="500"
            height="550"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactForm;
