// ProfilePage.js

import React from 'react';
import styles from './styles.module.css';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import driver from '../../images/drive.png';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
const Profile = () => {
    const history = useNavigate();

    const handleLogout = () => {
      // Clear localStorage
      localStorage.removeItem('userId'); // Replace 'accessToken' with the key you used for storing the access token
  
      // Navigate to sign-in screen
      history.push('/signin'); // Replace '/signin' with the route to your sign-in screen
    };
    return (
        <>
            <Navbar />
            <div className={styles.container}>
                
                    <div className={styles.profileHeader}>
                        <h2>Profile</h2>
                 
                    <div className={styles.profileContent}>
                        <img className={styles.profilePicture} style={{width:'200px',height:'200px'}} src={driver} alt="Profile" />
                        <div className={styles.profileDetails}>
                            <h3>User Name</h3>
                            <p>Email: user@example.com</p>
                            <p>Location: City, Country</p>
                            <p>Joined: January 1, 2023</p>
                            <p>Total Earning: Rs.12000</p>
                        </div>
                    </div>
                </div>
                <Link to='/'>
                <Button className={styles.btnLogout} variant="danger" onClick={handleLogout}>
                    Logout
                </Button>
                </Link>
            </div>
            <Footer />
        </>
    );
};

export default Profile;
