import React, { useEffect, useState } from "react";
import { Form, Button, Row } from "react-bootstrap";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../footer/Footer";
import { FaCamera } from "react-icons/fa";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AWS from "aws-sdk";
import axios from "axios";
import logo from '../../images/log.png';
import {SBaseUrl} from "../../Apis";
import "./Home.css";

const Home = () => {
  const [formValues, setFormValues] = useState({
    user_id: "",
    warranty_status: 'ew',
    contact_name: "",
    phone_number: "",
    email: "",
    device_brand: "",
    device_emi_number: "",
    device_images: [],
    product_value: "",
    price: "",
    phoneOtp: "",
    emailOtp: ""
  });
  const [formSubmitss, setFormSubmitss] = useState(false);
  const [showPhoneOtp, setShowPhoneOtp] = useState(false);
  const [showEmailOtp, setShowEmailOtp] = useState(false);
  const [razorpayInstance, setRazorpayInstance] = useState(null);
  const [taxPrice, setTaxPric] = useState(6);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const data = await localStorage.getItem('userId');
    if (data) {
      setFormValues((prevValues) => ({ ...prevValues, user_id: data }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let calculatedPrice = 0;
    if (name === 'product_value') {
      calculatedPrice = value * (taxPrice / 100);
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
      price: calculatedPrice.toFixed(2)
    }));
  };

  const handleOptionChange = (event) => {
    const { name, value } = event.target;
    if (name === 'warranty_status') {
      setTaxPric(value !== 'ew' ? 8 : 5);
    }
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    const imagesArray = Array.from(files);

    if (imagesArray.length < 2 || imagesArray.length > 5) {
      toast.error(imagesArray.length < 2
        ? 'Please upload at least two images.'
        : 'You can upload a maximum of five images.');
      return;
    }

    setFormValues((prevValues) => ({ ...prevValues, device_images: imagesArray }));
  };

  const uploadFileToS3 = async (file) => {
    const S3_BUCKET = "wecare1";
    const REGION = "ap-south-1";
    AWS.config.update({
      accessKeyId: "AKIAQBTFHEDHQ5QGDCOH",
      secretAccessKey: "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",
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: file.name,
      Body: file,
    };

    const data = await s3.putObject(params).promise();
    return `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${file.name}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const orderData = {
        amount: formValues.price,
        currency: 'INR',
        receipt: `OrderId+${formValues.contact_name}`
      };
      const result = await axios.post(`${SBaseUrl}/orders`, orderData);
      if (!result) {
        throw new Error("Server error. Are you online?");
      }
      const { amount, id: order_id, currency } = result.data;
      initiateRazorpayPayment(amount, currency, order_id);
    } catch (error) {
      console.error("Error occurred during order creation:", error);
      alert("Error occurred while creating the order.");
    }
  };

  const initiateRazorpayPayment = (amount, currency, order_id) => {
    const options = {
      key: "rzp_live_ENhCkE8WsxBPCW",
      // key:'rzp_test_UJM4MuVRK38yqt',
      amount: amount.toString(),
      currency: currency,
      name: "We Care",
      description: "Test Transaction",
      image: { logo },
      order_id: order_id,
      handler: handlePaymentSuccess,
      prefill: {
        name: formValues.contact_name,
        email: formValues.email,
        contact: formValues.phone_number,
      },
      notes: {
        address: "Soumya Dey Corporate Office",
      },
      theme: {
        color: "#61dafb",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const handlePaymentSuccess = async (response) => {
    try {
      const paymentData = {
        orderCreationId: response.razorpay_order_id,
        razorpayPaymentId: response.razorpay_payment_id,
        razorpayOrderId: response.razorpay_order_id,
        razorpaySignature: response.razorpay_signature,
      };
      const result = await axios.post(`${SBaseUrl}/paymentsuccess`, paymentData);
      if (result.data.msg) {
        alert(result.data.msg);
      }
      const uploadedUrls = await Promise.all(formValues.device_images.map(uploadFileToS3));
      await submitLeadForm(uploadedUrls);
    } catch (error) {
      console.error("Payment success handling failed:", error);
    }
  };

  const submitLeadForm = async (uploadedUrls) => {
    try {
      const response = await axios.post(`${SBaseUrl}/add-lead`, {
        ...formValues,
        device_images: uploadedUrls
      });
      console.log("Lead form submitted:", response.data);
      setFormSubmitss(true);
    } catch (error) {
      console.error("Error occurred while submitting lead form:", error);
    }
  };

  const sendPhoneOtp = () => {
    // Implement OTP sending logic
  };

  const verifyPhoneOtp = () => {
    // Implement OTP verification logic
  };

  const sendEmailOtp = () => {
    // Implement OTP sending logic
  };

  const verifyEmailOtp = () => {
    // Implement OTP verification logic
  };

  return (
    <>
      <ToastContainer />
      <Navbar />
      <div className="home-container">
        {formSubmitss ? (
          <div className="cord">
            Thanks for submitting this form. Our support will coordinate with you!
          </div>
        ) : (
          <div className="container mt-5 form-container">
            <h2 className="form-title">Register For New Lead</h2>
            <Form onSubmit={handleSubmit}>
              <div className="radio-group">
                <div className="radio-item">
                  <label>
                    <input
                      type="radio"
                      name="warranty_status"
                      value="ew"
                      checked={formValues.warranty_status === "ew"}
                      onChange={handleOptionChange}
                    />
                    EW
                  </label>
                </div>
                <div className="radio-item">
                  <label>
                    <input
                      type="radio"
                      name="warranty_status"
                      value="dp"
                      checked={formValues.warranty_status === "dp"}
                      onChange={handleOptionChange}
                    />
                    DP
                  </label>
                </div>
                <div className="radio-item">
                  <label>
                    <input
                      type="radio"
                      name="warranty_status"
                      value="cpp"
                      checked={formValues.warranty_status === "cpp"}
                      onChange={handleOptionChange}
                    />
                    CPP
                  </label>
                </div>
              </div>
              <Row>
                <Form.Group controlId="formContactName" className="colls">
                  <Form.Label>Contact Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="contact_name"
                    placeholder="Enter contact name"
                    value={formValues.contact_name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formPhoneNumber" className="colls">
                  <Form.Label>Phone Number*</Form.Label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">+91</span>
                    </div>
                    <Form.Control
                      type="tel"
                      name="phone_number"
                      placeholder="Enter phone number"
                      value={formValues.phone_number}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  {showPhoneOtp && (
                    <>
                      <Button className="send-otp-button" onClick={sendPhoneOtp}>Send OTP</Button>
                      <Form.Control
                        type="text"
                        name="phoneOtp"
                        placeholder="Enter OTP"
                        value={formValues.phoneOtp}
                        onChange={handleChange}
                        required
                      />
                      <Button className="verify-otp-button" onClick={verifyPhoneOtp}>Verify OTP</Button>
                    </>
                  )}
                </Form.Group>
              </Row>
              <Row>
                <Form.Group controlId="formEmail" className="colls">
                  <Form.Label>Email*</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    value={formValues.email}
                    onChange={handleChange}
                    required
                  />
                  {showEmailOtp && (
                    <>
                      <Button className="send-otp-button" onClick={sendEmailOtp}>Send OTP</Button>
                      <Form.Control
                        type="text"
                        name="emailOtp"
                        placeholder="Enter OTP"
                        value={formValues.emailOtp}
                        onChange={handleChange}
                        required
                      />
                      <Button className="verify-otp-button" onClick={verifyEmailOtp}>Verify OTP</Button>
                    </>
                  )}
                </Form.Group>
              </Row>
              <Row>
                <Form.Group controlId="formDeviceBrand" className="colls">
                  <Form.Label>Device Brand*</Form.Label>
                  <Form.Control
                    type="text"
                    name="device_brand"
                    placeholder="Enter device brand"
                    value={formValues.device_brand}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formDeviceEmi" className="colls">
                  <Form.Label>Device IMEI Number*</Form.Label>
                  <Form.Control
                    type="text"
                    name="device_emi_number"
                    placeholder="Enter device EMI number"
                    value={formValues.device_emi_number}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formDeviceImages" className="colls">
                  <Form.Label>Device Images*</Form.Label>
                  <div className="file-upload">
                    <label htmlFor="file-upload" className="file-upload-label">
                      <FaCamera /> Upload Images
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      onChange={handleImageChange}
                      accept="image/*"
                      multiple
                    />
                  </div>
                  {formValues.device_images.length > 0 && (
                    <div className="file-upload-display">
                      {formValues.device_images.map((image, index) => (
                        <span key={index}>{image.name}</span>
                      ))}
                    </div>
                  )}
                  {formValues.device_images.length > 0 && (
                    <div className="image-preview">
                      {formValues.device_images.map((image, index) => (
                        <img
                          key={index}
                          src={URL.createObjectURL(image)}
                          alt={`Preview ${index}`}
                          className="preview-image"
                        />
                      ))}
                    </div>
                  )}
                </Form.Group>
                <Form.Group controlId="formProductValue" className="colls">
                  <Form.Label>Product Value (as per calculation)</Form.Label>
                  <Form.Control
                    type="text"
                    name="product_value"
                    placeholder="Enter product value"
                    value={formValues.product_value}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formPrice" className="colls">
                  <Form.Label>Paying Value (as per calculation)</Form.Label>
                  <Form.Control
                    type="text"
                    name="price"
                    placeholder="Enter Calculated value"
                    value={formValues.price}
                    readOnly
                  />
                </Form.Group>
              </Row>
              <Button className="submit-button" variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Home;
