
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
} from "react-router-dom";
import Signin from "./components/Signin";
import Home from '../src/components/Home/index';
import DetailsSubmit from "./components/DetailsSubmit";
import OrderList from "./components/orderList.js";
import Profile from "./components/Profile/index.jsx";
import ContactForm from "./components/ContactForm/ContactForm.jsx";
import Footer from "./components/footer/Footer.jsx";
import RazorpayPayment from "./components/RazorpayPayment.jsx";
import Terms from "./components/Terms/index.js";
import Privacy from "./components/Privacy/index.js";
import AboutUs from "./components/About/index.js";
import RefundPolicy from "./components/Refund/index.js";
export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/home" element={<Home />} />
        <Route path="/detailSubmit" element={<DetailsSubmit />} />
        <Route path="/OrderList" element={<OrderList />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/payment" element={<RazorpayPayment />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/RefundPolicy" element={<RefundPolicy/>}/>
        <Route path="/AboutUs" element={<AboutUs />} />
      </Routes>
      {/* <Footer /> */}
    </BrowserRouter>
  );
}