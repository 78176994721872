import { useState, useEffect, useLayoutEffect } from "react";
import WithoutSignNavbar from './WithouSignNavbar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './footer/Footer';
import './signin.css';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { SBaseUrl } from "../Apis";

function Signin() {
  const initialValues = { email: "", password: "", type: "agent" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const history = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);
    setIsSubmit(true);
    try {
      const response = await axios.post(`${SBaseUrl}/login`, formValues, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (response.status === 200 && Object.keys(errors).length === 0) {
        console.log("Signin Data is>>", response.data);
        localStorage.setItem('userId', response?.data?.data?._id)
        handleRedirect(response?.data);
      } else {
        toast.error("Invalid credentials. Please try again.");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useLayoutEffect(() => {
    getUser()
  }, [])
  const getUser = async () => {
    const data = await localStorage.getItem('userId')
    if (data) {
      history("/home");
    } else {
      history("/");
    }
  }
  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email) {
      errors.email = "Email is required!";
      toast.error(errors.email);
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
      toast.error(errors.email);
    }
    if (!values.password) {
      errors.password = "Password is required";
      toast.error(errors.password);
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
      toast.error(errors.password);
    } else if (values.password.length > 10) {
      errors.password = "Password cannot exceed more than 10 characters";
      toast.error(errors.password);
    }
    return errors;
  };

  const handleRedirect = (response) => {
    if (formValues.type === 'user') {
      history("/home");
    } else if (formValues.type === 'agent') {
      // console.log("Signin>",response?.data?.Gst.length>2)
      if (response?.data?.Gst?.length > 2) {
        history("/home");
      } else {
        history("/detailSubmit")
      }
    }
  };

  const openPp = () => {
    history('/Privacy')
  }
  const openTP = () => {
    history('/Terms')
  }
  return (
    <>
      <WithoutSignNavbar />
      <div className="contain">

        <form className="form-inputs" onSubmit={handleSubmit}>
          <h3>Please use the Login credential</h3>
          <div className="ui divider"></div>
          <div className="ui form">
            <div className="fields">
              <label>Email</label>
              <input
                type="text"
                name="email"
                placeholder="Email"
                value={formValues.email}
                onChange={handleChange}
              />
            </div>
            <p>{formErrors.email}</p>
            <div className="fields">
              <label>Password</label>
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={formValues.password}
                onChange={handleChange}
              />
            </div>
            <p>{formErrors.password}</p>
            <div className="fields">
              <label>User Type</label>
              <div>
                <label>
                  <input
                    type="radio"
                    name="type"
                    value="user"
                    checked={formValues.type === 'user'}
                    onChange={handleChange}
                  />
                  User
                </label>
                <label>
                  <input
                    type="radio"
                    name="type"
                    value="agent"
                    checked={formValues.type === 'agent'}
                    onChange={handleChange}
                  />
                  Agent
                </label>
              </div>
            </div>
            <button className="buttn" >Submit</button>
            {/* <div style={{ marginTop: 10 }}>
              <span onClick={() => openPp()} style={{ marginLeft: 10, textDecorationLine: 'underline', textDecorationColor: 'blue', cursor: 'pointer' }}>Privacy Policy</span>
              <span onClick={() => openTP()} style={{ marginLeft: 10, textDecorationLine: 'underline', textDecorationColor: 'blue', cursor: 'pointer' }} >Terms & Condition</span>
            </div> */}
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}

export default Signin;
