import React from 'react';
import './AboutUs.css';
import WithouSignNavbar from '../WithouSignNavbar';
import Footer from '../footer/Footer';
// import WithoutSignNavbar from './WithouSignNavbar';
function AboutUs() {
  return (
    <>
    <WithouSignNavbar/>
    <div className="about-us-container">
      <div >
        <h1>About Us</h1>
        <p>
        Welcome to our website! We are a team of passionate individuals dedicated
          to providing you with the best possible experience.
        </p>
        <p> KABUJI SERVICES INDIA PRIVATE LIMITED.</p>
      </div>
    </div>
    {/* <Footer/> */}
    </>
  );
}

export default AboutUs;
