// LoginPage.js
import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import WithouSignNavbar from './navbar/Navbar';
import Footer from './footer/Footer';
import './detailSubmit.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { SBaseUrl } from '../Apis';
const DetailsSubmit = () => {
    const initialValues = { user_id: '', gst: "", pan: "", aadhar: "agent", farm_no: "", owner_name: "", account_no: "", mobile_no: "", ifscCode: '', bankName: '' };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const history = useNavigate();
    const handleSubmit = async (e) => {
        console.log("ee", e)
        e.preventDefault();
        const errors = validate(formValues);
        setFormErrors(errors);
        setIsSubmit(true);
        try {
            const response = await axios.post(`${SBaseUrl}/update-agent-data`, formValues, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            console.log("Update data", response);
            if (response.status === 200) {
                history("/home");
            } else {
                toast.error("Invalid credentials. Please try again.");
            }
        } catch (error) {
            console.log(error);
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    useEffect(() => {
        getuserData()
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log(formValues);
        }
    }, [formErrors]);
    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.email) {
            errors.email = "Email is required!";
            toast.error(errors.email);
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
            toast.error(errors.email);
        }
        if (!values.password) {
            errors.password = "Password is required";
            toast.error(errors.password);
        } else if (values.password.length < 4) {
            errors.password = "Password must be more than 4 characters";
            toast.error(errors.password);
        } else if (values.password.length > 10) {
            errors.password = "Password cannot exceed more than 10 characters";
            toast.error(errors.password);
        }
        return errors;
    };
    const getuserData = async () => {
        let data = await localStorage.getItem('userId')
        console.log("user id  from local storage >", data)
        if (data) {
            setFormValues({ ...formValues, user_id: data });
        }
    }
    return (
        <>
            <WithouSignNavbar />
            <div className="container mt-6 mt-4" >
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicGST">
                        <Form.Label>GST*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="GST"
                            value={formValues?.gst}
                            name="gst"
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicPAN">
                        <Form.Label>PAN*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="PAN"
                            value={formValues?.pan}
                            name='pan'
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicAadhar">
                        <Form.Label>Aadhar*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Aadhar"
                            name='aadhar'
                            value={formValues?.aadhar}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicFarmNo">
                        <Form.Label>Farm Number*.</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Farm No."
                            name='farm_no'
                            value={formValues?.farm_no}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicOwnerName">
                        <Form.Label>Owner Name*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Owner Name"
                            name='owner_name'
                            value={formValues?.owner_name}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicAccountNumber">
                        <Form.Label>Account Number*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Account Number"
                            name='account_no'
                            value={formValues?.account_no}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicAccountNumber">
                        <Form.Label>IFSC Code*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="IFSC Code"
                            name='ifscCode'
                            value={formValues?.ifscCode}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicAccountNumber">
                        <Form.Label>Bank Details*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Bank Name"
                            name='bankName'
                            value={formValues?.bankName}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicMobileNumber">
                        <Form.Label>Mobile Number*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Mobile Number"
                            name='mobile_no'
                            value={formValues?.mobile_no}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    {/* <Link to="/home"> */}
                    <button className="buttn" >Submit</button>
                    {/* </Link> */}
                </Form>
            </div>
            <Footer />
        </>
    );
};

export default DetailsSubmit;
