import React, { useState, useEffect } from 'react';
import { Container, Table, Pagination } from 'react-bootstrap';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import './OrderList.css'
import { SBaseUrl } from '../../Apis';

const OrderList = () => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(10);

    useEffect(() => {
        const getAllUsersData = async () => {
            try {
                const response = await fetch(`${SBaseUrl}/show-all-leads`);

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const jsonResponse = await response.json();
                if (jsonResponse.success && Array.isArray(jsonResponse.data)) {
                    setUsers(jsonResponse.data.reverse()); // Reverse the users array
                } else {
                    console.error("Unexpected JSON structure: ", jsonResponse);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        getAllUsersData();
    }, []);

    // Get current users
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <>
            <Navbar />
            <Container className="order">
                <h4>Order Records</h4>
                <Table className='table'>
                    <thead className='table-head'>
                        <tr>
                            <th>Sr.No</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Device EMI No.</th>
                            <th>Product Price</th>
                            <th>Our Price</th>
                        </tr>
                    </thead>
                    <tbody className='table-body'>
                        {currentUsers.map((user, index) => (
                            <tr key={user._id}>
                                <td>{indexOfFirstUser + index + 1}</td>
                                <td>{user.contact_name}</td>
                                <td>{user.email}</td>
                                <td>{user.phone_number}</td>
                                <td>{user.device_emi_number}</td>
                                <td>{user.product_value}</td>
                                <td>{user.price}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Pagination>
                    <Pagination.Prev onClick={() => setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)} />
                    {[...Array(Math.ceil(users.length / usersPerPage)).keys()].map(number => (
                        <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
                            {number + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => setCurrentPage(currentPage === Math.ceil(users.length / usersPerPage) ? currentPage : currentPage + 1)} />
                </Pagination>
            </Container>
            <Footer />
        </>
    );
};

export default OrderList;
