import { React, useState } from 'react';
import styles from '../navbar/Navbar.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../images/logoo.gif';
import {
    AiOutlineUser,
    AiOutlineSearch,
    AiOutlineMenu,
    AiOutlineClose,
} from 'react-icons/ai';
import { Link } from 'react-router-dom';

const WithouSignNavbar = () => {
    const [nav, setNav] = useState(false);

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleClick = () => {
        if (!isLoggedIn) {
            toast.error('Please login first');
        } else {

        }
    };

    return (
        <header className={styles.navbar}>
            <Link to="/">
                <img
                    src={logo}
                    alt="logo"
                    style={{
                        fontWeight: 'bold',
                        width: '70px'
                    }}
                />
            </Link>
            <nav>
                <ul className={nav ? [styles.menu, styles.active].join(' ') : [styles.menu]} >
                    <li>
                        <Link to="/contact">
                            <a href='/contact' style={{ color: 'black' }}>Contact us</a>
                        </Link>
                    </li>
                    <li>
                        <AiOutlineUser color='black' size={25} style={{ marginTop: '3px' }} onClick={handleClick} />
                    </li>
                    <ToastContainer position="top-right" />
                </ul>
            </nav>
            <div onClick={() => setNav(!nav)} className={styles.mobile_btn}>
                {nav ? <AiOutlineClose color='black' size={30} /> : <AiOutlineMenu color='black' size={30} />}
            </div>
        </header>
    );
};

export default WithouSignNavbar;
