import React from 'react';

const RazorpayPayment = ({ productValue }) => {
  const openPaymentModal = async () => {
    const options = {
      key: 'rzp_live_ENhCkE8WsxBPCW',
      amount: 600,
      currency: 'INR',
      name: 'We Care',
      description: 'Product Payment',
      order_id: '1',
      handler: function (response) {
        alert(response.razorpay_payment_id);
      },
      prefill: {
        name: '', // Example name
        email: 'john@example.com', // Example email
        contact: '+1234567890', // Example phone number
      },
      notes: {
        address: '123, Example Street, City, Country', // Example address
      },
      theme: {
        color: '#3399cc',
      },
    };
  
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  

  return (
    <button onClick={openPaymentModal} style={{ display: 'block', margin: '0 auto' }}>Proceed to Pay</button>

  );
};

export default RazorpayPayment;
