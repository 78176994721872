import React from 'react';
import WithouSignNavbar from '../WithouSignNavbar';

function RefundPolicy() {
    return (
        <>
        <WithouSignNavbar/>
        <div style={{margin:`20px`}}>
            <h1>Return, Refund, and Cancellation Policy</h1>
            <h2>Returns & Replacement</h2>
            <p>
                All products purchased on  <a href="www.wecare.ind.in"  target='_blank'>wecare.ind.in</a>. can be returned/replaced within 48 hours of purchase.
                Orders placed beyond 48 hours are not eligible for refunds, but can be either replaced or fixed under the terms and conditions of the warranty of that product.
            </p>
            <p>
                In order to claim a refund for the product please email your request along with the order number to <a href="mailto:support@wecare.ind.in">support@wecare.ind.in</a>.
                After receiving, the support team will verify, approve, and process the refund.
            </p>
            <h2>Refund method</h2>
            <ol>
                <li>After reviewing the purchase, we will inspect it.</li>
                <li>We will communicate the status of your refund after the inspection.</li>
                <li>After approval, we will initiate a refund to your original method of payment.</li>
                <li>If the payment method at the time of purchase is credit card / debit card / wallet or net banking the total price of the product will be returned to the same mode of payment.</li>
                <li>We will refund the amount within 7 working days to the original method of payment.</li>
            </ol>
            <h2>Cancellation Policy</h2>
            <p>
                For Cancellations WeCare Support to be contacted by various methods mentioned under the contact us link. Please quote Order No in all communications.
                It is advised that the query is sent from the registered email Id.
            </p>
            <p>
                Cancellation requests can be raised at any time before the shipping of the order.
                Your order will be canceled instantly and a refund will be processed within 3 to 4 working days in your original payment method.
            </p>
            <p>
                Following deductions will be made under Refund Process:
            </p>
            <ul>
                <li>Cancellation charges: 0.5% of order value, minimum of Rs 10/-, maximum up to Rs 50/-</li>
                <li>Online Transaction Charges: 3.25% of the total payment made.</li>
            </ul>
            <p>
                Note: GST paid during purchase will not be refunded.
            </p>
        </div>
        </>
    );
}

export default RefundPolicy;
